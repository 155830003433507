<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn v-if="helperService.userHasPermission('fournisseur_add')" class="action-btn" icon="create" color="primary"
          text="Ajouter un fournisseur" :to="{ name: 'supplierAdd' }" />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <Search v-model="search" searchEndpoint="fournisseur" searchPlaceholder="Rechercher un fournisseur">
          <template v-slot:suggestions="{ item }">
            {{ item.nom }}
          </template>

          <SelectExtended id="thematique" v-model="search.filters.thematique.values" search multiple label="Thématique"
            optionKey="uid" optionValue="designation" apiEndpoint="dictionnaire/thematique" />
        </Search>

        <List :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key" @send-data="setData">
          <template v-slot:group-actions="{ selectedItems }">
            <Btn v-if="helperService.userHasPermission('fournisseur_remove')" text="Supprimer" color="default"
              @click="openModal('deleteItems', selectedItems)" />
          </template>

          <template v-slot:thematique="{ item }">
            <div class="tags">
              <MiniTag v-for="(thematique, index) in item.thematiques" :key="index" :id="thematique.id"
                :text="thematique.designation" color="primary" />
            </div>
          </template>

          <template v-slot:actions="{ item }">
            <Btn v-if="helperService.userHasPermission('fournisseur_edit')" default :to="{
              name: 'supplierEdit',
              params: { id: item.id },
            }" icon="create" round grow />
            <Btn v-if="helperService.userHasPermission('fournisseurcontact_olist')" default icon="users-group"
              :to="{ name: 'supplierContacts', params: { fId: item.id } }" round grow />
          </template>
        </List>
      </Section>
      <Modal title="Supprimer des profils&nbsp;?" :active="modal.deleteItems" :data="modalData"
        @modal-close="modal.deleteItems = false">
        <template v-slot:modal-body="{ data }">
          <p>
            Voulez vous vraiment supprimer
            {{ data.length }} fournisseurs&nbsp;?
          </p>
        </template>
        <template v-slot:modal-footer="{ data }">
          <Btn text="Annuler" @click="modal.deleteItems = false" />
          <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'
import Search from '@/components/list/Search.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'SuppliersView',

  components: {
    MiniTag,
    SelectExtended,
    Search,
    List,
    Pagination,
    Btn,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      search: {
        attributs: ['nom'],
        filters: {
          thematique: {
            type: 'object',
            key: 'uid',
            values: [],
          },
        },
      },
      list: {
        api: {
          endpoint: 'fournisseur',
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom', dataField: 'nom' },
          { label: 'Thématique' },
        ],
        col: ['nom', 'thematique'],
        key: 'id',
      },
      data: [],
      modalData: {},
      modal: {
        deleteItems: false,
      },
    }
  },

  mounted() {
    if (this.helperService.userHasProfil('super-administrateur')) {
      this.list.headers.push({ label: 'UID', dataField: 'uid' })
      this.list.col.push('uid')
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`fournisseur/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `Le fournisseur ${item.nom} a bien été supprimé.`,
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },

    setData(data) {
      this.data = data
    },
  },
}
</script>

<style lang="scss" scoped>
.role-profile {
  padding: $gutter-half;
}

.list-row {
  border: 1px solid grey;
}
</style>
